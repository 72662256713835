import * as React from "react"
import { Container, Row, Col } from "react-bootstrap";

import SEO from "../components/seo";
import Layout from "../components/layouts/layout";
import Image from "../components/widgets/basico/image";

import TabsModelosCarrousel from "../components/widgets/TabsModelosCarrousel";

export default function NotFoundPage(props){

  return(
    <Layout>
        <SEO title="404: Not found" path="/404" />
        <Container>
              <Image 
                fileName="grupo-rivero-404" alt="Grupo Rivero 404"
                style={{height: 550}}
                imgStyle={{height:'100%'}}
              />
          <center>
            <div className="text-white">
              <h1>Página no encontrada</h1>
              <p>Aqui podrás encontrar algo que te puede interesar</p>
              
            </div>
          </center>
          <TabsModelosCarrousel props={props}/>
        </Container>
    </Layout>
  )

}
